<template>
  <div class="itinerary-view tw-mb-10">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "ItineraryView",
};
</script>
